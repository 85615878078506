import { useQuery } from '@tanstack/react-query'
import { ApiError, FrameworkStatsSchema, PagedFrameworkSchema } from '../generated'
import { useApi } from './api'

export const useListFrameworks = (query: {
  name?: string
  shortName?: string
  isRoot?: boolean
  orderBy?: string
  page?: number
  pageSize?: number
}) => {
  const api = useApi()

  return useQuery<PagedFrameworkSchema, ApiError>({
    queryKey: ['listFrameworks', query],
    queryFn: () => api.listFrameworks(query),
  })
}

export const useFrameworkLeafs = ({
  rootId,
  hasTags,
  isFlagged,
  hasComments,
}: {
  rootId?: string
  hasTags?: boolean
  isFlagged?: boolean
  hasComments?: boolean
}) => {
  const api = useApi()

  return useQuery<PagedFrameworkSchema, ApiError>({
    queryKey: ['getFrameworkLeafs', { id: rootId, hasTags, isFlagged, hasComments }],
    queryFn: () =>
      api.getFrameworkLeafs({
        id: rootId as string,
        hasTags,
        isFlagged,
        hasComments,
        pageSize: 500,
      }),
    enabled: !!rootId,
  })
}

export const useFrameworkStats = (id?: string) => {
  const api = useApi()

  return useQuery<FrameworkStatsSchema[], ApiError>({
    queryKey: ['getFrameworkStats', { id }],
    queryFn: () => api.getFrameworkStats({ id: id as string }),
    enabled: !!id,
  })
}
