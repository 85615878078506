import { useState, useMemo } from 'react'
import { Grid, StyleProps, useTheme, alpha, Box } from '@mui/material'
import { ChartData, StackedBarChart, Typography } from '@components'
import { useNavigate, useParams } from 'react-router-dom'
import { useListFeedbackTags, useListRationaleFeedbacks } from '@core/api/hooks'
const styles: StyleProps = {
  title: {
    pb: 2,
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    bgcolor: 'N000',
    boxShadow: (theme) =>
      `0 1px 1px 0 ${alpha(theme.palette.N900, 0)}, 0 0 1px 0 ${alpha(theme.palette.N900, 0)}`,
    p: 2,
    my: 3,
  },
}

export const ReportChart = () => {
  const { projectId } = useParams() as { projectId: string }
  const [focusLabel, setFocusLabel] = useState<string | undefined>()
  const theme = useTheme()
  const navigate = useNavigate()

  const { data: dataFeedbackTags, isLoading: isLoadingFeedbackTags } =
    useListFeedbackTags(projectId)
  const { data: dataFeedbackRationale, isLoading: isLoadingFeedbackRationale } =
    useListRationaleFeedbacks(projectId)

  const tagsData = useMemo(
    () =>
      dataFeedbackTags?.map((item) => ({
        ...item,
        name: item.shortName,
        legend: `${item.totalTagFeedbacks}/${item.totalUnits}`,
      })) as ChartData[],
    [dataFeedbackTags]
  )

  const rationalesData = useMemo(
    () =>
      dataFeedbackRationale?.map((item) => ({
        ...item,
        name: item.shortName,
        legend: `${item.totalRationaleFeedbacks}/${item.totalTags}`,
      })) as ChartData[],
    [dataFeedbackRationale]
  )

  const commonNames = useMemo(() => {
    if (!rationalesData || !tagsData) return []
    return tagsData
      .filter((item) => rationalesData.some((item2) => item2.shortName === item.shortName))
      .map((item) => item.shortName as string)
  }, [rationalesData, tagsData])

  const onClick = (label: string | undefined) => {
    if (!label) return
    navigate(`../review/${projectId}?tagName=${label}&page=1`)
  }

  const onFocus = (label: string | undefined) => {
    setFocusLabel(label)
  }

  return (
    <Grid container sx={styles.container}>
      <Grid item container direction="column" md={6}>
        <Box pb={2}>
          <Typography variant="body1" fontWeight={600}>
            Most Frequently Changed AI Assigned Tags
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          The AI-assigned tags below have been changed most often by users.
        </Typography>
        <Box pb={2}>
          <StackedBarChart
            isLoading={isLoadingFeedbackTags}
            noDataMessage={'This project currently has no changed AI-assigned tags'}
            data={tagsData}
            focusedLabel={focusLabel}
            chartSettings={{
              legendName: 'legend',
              yName: 'shortName',
              bar: [
                {
                  dataKey: 'notAppropriate',
                  color: theme.palette.O600,
                },
                {
                  dataKey: 'acceptable',
                  color: theme.palette.O500,
                },
                {
                  dataKey: 'other',
                  color: theme.palette.O300,
                },
              ],
            }}
            commonNames={commonNames}
            onClick={onClick}
            onFocus={onFocus}
          />
        </Box>
      </Grid>
      <Grid item container direction="column" md={6}>
        <Grid item md={6} pb={2}>
          <Typography variant="body1" fontWeight={600}>
            Most Frequently Downvoted AI Assigned Rationales
          </Typography>
        </Grid>
        <Typography variant="subtitle1">
          The AI-assigned rationales below have been downvoted most often by users.
        </Typography>
        <StackedBarChart
          isLoading={isLoadingFeedbackRationale}
          data={rationalesData}
          noDataMessage={'This project currently has no downvoted AI-assigned rationales'}
          focusedLabel={focusLabel}
          chartSettings={{
            legendName: 'legend',
            yName: 'shortName',
            bar: [
              {
                dataKey: 'undesired',
                color: theme.palette.A600,
              },
              {
                dataKey: 'inaccurate',
                color: theme.palette.A500,
              },
              { dataKey: 'other', color: theme.palette.O300 },
            ],
          }}
          commonNames={commonNames}
          onClick={onClick}
          onFocus={onFocus}
        />
      </Grid>
    </Grid>
  )
}
