import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ApiError,
  PagedProjectSchema,
  ProjectSchema,
  ProjectUpdateSchema,
  TagFeedbackSchema,
  RationaleFeedbackSchema,
} from '../generated'
import { useApi } from './api'
import axios from 'axios'
import { FTC_API, IS_STORYBOOK } from '@core/config'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router-dom'

export const useProject = (id?: string) => {
  const api = useApi()

  return useQuery<ProjectSchema, ApiError>({
    queryKey: ['getProject', { id }],
    queryFn: () => api.getProject({ id: id as string }),
    enabled: !!id,
  })
}

export const useListFeedbackTags = (projectId?: string) => {
  const api = useApi()
  return useQuery<TagFeedbackSchema[], ApiError>({
    queryKey: ['listFeedbacks', { id: projectId }],
    queryFn: () => api.listFeedback({ id: projectId as string }),
    enabled: !!projectId,
  })
}

export const useListRationaleFeedbacks = (projectId?: string) => {
  const api = useApi()
  return useQuery<RationaleFeedbackSchema[], ApiError>({
    queryKey: ['listRationaleFeedbacks', { id: projectId }],
    queryFn: () => api.listRationaleFeedbacks({ id: projectId as string }),
    enabled: !!projectId,
  })
}

export const useListProject = (query: Record<string, string | string[]>) => {
  const api = useApi()

  return useQuery<PagedProjectSchema, ApiError>({
    queryKey: ['listProject', query],
    queryFn: () => api.listProjects(query),
    staleTime: 0,
  })
}

export const useUpdateTitle = ({ id }: { id: string }) => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation<ProjectSchema, ApiError, ProjectUpdateSchema>({
    mutationFn: ({ title }) =>
      api.updateProject({
        id,
        requestBody: {
          title,
        },
      }),
    onSuccess: (response) => {
      queryClient.setQueryData(['getProject', { id }], response)
    },
    onError: () => {}, // overwrite default error handling
  })
}

export const useDeleteProject = () => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation<void, ApiError, { id: string }>({
    mutationFn: ({ id }) => api.deleteProject({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listFrameworks'] })
    },
  })
}

export const useBulkDeleteProjects = () => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation<void, ApiError, { ids: string[] }>({
    mutationFn: ({ ids }) => api.bulkDeleteProjects({ requestBody: { ids } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listFrameworks'] })
    },
  })
}

export const useLazyExportProject = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { customer } = useParams()

  const exportProject = async (id: string) => {
    const token = IS_STORYBOOK ? 'token' : await getAccessTokenSilently()
    const axiosInstance = axios.create({
      baseURL: FTC_API,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    })
    const res = await axiosInstance.get(`/tenants/${customer}/projects/${id}/export`)
    const blob = new Blob([res.data], { type: res.data.contentType })
    return window.URL.createObjectURL(blob)
  }

  return {
    exportProject,
  }
}
