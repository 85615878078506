import React, { useEffect, useMemo, useState } from 'react'
import { FilterListRounded as FilterListRoundedIcon } from '@mui/icons-material'
import { Box, Skeleton } from '@mui/material'
import { useParams, useSearchParams } from 'react-router-dom'

import { Autocomplete, AutocompleteOption, Chip, IconButton, Tooltip } from '@components'

import { FilterDrawer } from './filter-drawer'
import { useQuery } from '@tanstack/react-query'
import { ProjectSchema } from '@core/api/generated'

import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { Typography } from '@components'
import { useFrameworkLeafs, useListUnits } from '@core/api/hooks'

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]

  const inlineStyle = {
    ...style,
    padding: 0,
  }

  return (
    <Box component="ul" {...dataSet[0]} style={inlineStyle}>
      {dataSet}
    </Box>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const ITEM_SIZE = 40

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData: React.ReactElement[] = []
    ;(children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item)
        itemData.push(...(item.children || []))
      }
    )

    const itemCount = itemData.length

    const getHeight = () => {
      return itemCount * ITEM_SIZE
    }

    const innerElementType = (innerProps: React.HTMLAttributes<HTMLUListElement>) => {
      const { style, ...rest } = innerProps

      return (
        <ul
          {...rest}
          style={{
            ...style,
            margin: 0,
          }}
        />
      )
    }

    return (
      <Box ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            itemData={itemData}
            height={getHeight()}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType={innerElementType}
            itemCount={itemCount}
            itemSize={ITEM_SIZE}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </Box>
    )
  }
)

const TagSearch = () => {
  const { projectId } = useParams() as { projectId: string }
  const [searchParams, setSearchParams] = useSearchParams()
  const [tagNames, setTagNames] = useState<AutocompleteOption[]>([])

  const { data: project } = useQuery<ProjectSchema>({ queryKey: ['getProject', { id: projectId }] })
  const query = Object.fromEntries(searchParams)
  const { data: leafs } = useFrameworkLeafs({ rootId: project?.framework.id, ...query })

  useEffect(() => {
    const tags = searchParams.getAll('tagName')
    setTagNames(tags.map((tag) => ({ label: tag, value: tag })))
  }, [searchParams])


  const handleChange = (selectedOptions: AutocompleteOption[]) => {
    setSearchParams({
      ...query,
      tagName: selectedOptions.map(({ value }) => value) as string[],
      page: '1',
    })
    setTagNames(selectedOptions)
  }

  const options = useMemo(() => {
    if (!leafs?.items) return []

    return leafs.items.map((leaf) => {
      return {
        label: leaf.shortName,
        value: leaf.shortName,
      }
    })
  }, [leafs])

  return !project ? (
    <Skeleton height={36} variant="rounded" />
  ) : (
    <Autocomplete
      multiple
      ListboxComponent={ListboxComponent}
      placeholder="Enter a tag..."
      options={options}
      onChange={(_, e) => handleChange(e as AutocompleteOption[])}
      value={tagNames}
      size="small"
      renderOption={(props, option) => {
        return (
          <Tooltip title={option.label} placement="left">
            <Box component="li" {...props}>{option.label}</Box>
          </Tooltip>
        )
      }}
      filterSelectedOptions
      disableClearable
    />
  )
}

const FilterResultsTag = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const hasTags = searchParams.get('hasTags')
  const isFlagged = searchParams.get('isFlagged')
  const hasComments = searchParams.get('hasComments')

  return (
    <>
      {hasTags && (
        <Chip
          onDelete={() => {
            searchParams.delete('hasTags')
            setSearchParams(searchParams)
          }}
          size="small"
          key="hasTags"
          label="No Tags"
        />
      )}

      {isFlagged && (
        <Chip
          onDelete={() => {
            searchParams.delete('isFlagged')
            setSearchParams(searchParams)
          }}
          size="small"
          key="isFlagged"
          label="Flagged"
        />
      )}

      {hasComments && (
        <Chip
          onDelete={() => {
            searchParams.delete('hasComments')
            setSearchParams(searchParams)
          }}
          size="small"
          key="hasComments"
          label="Comments"
        />
      )}
    </>
  )
}

const FilterTags = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tags = searchParams.getAll('tagName')

  const query = Object.fromEntries(searchParams)

  if (query?.length || !tags) return null

  return (
    <>
      {tags.map((tag, index) => {
        return (
          <Chip
            onDelete={() => {
              setSearchParams({ ...query, tagName: tags.filter((x) => x !== tag) })
            }}
            size="small"
            key={index}
            label={tag}
          />
        )
      })}
    </>
  )
}

const styles = {
  root: {
    display: 'flex',
    width: '100%',
  },
  filter: {
    display: 'flex',
    gap: 1.5,
    alignItems: 'center',
    mr: 4.5,
  },
  tags: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  input: {
    ml: 'auto',
    width: 180,
  },
}

export default function ReviewMenu() {
  const [open, setOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const { projectId } = useParams() as { projectId: string }
  const { data, isLoading } = useListUnits({ projectId, searchParams })

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Box sx={styles.root}>
      <FilterDrawer open={open} handleClose={handleClose} />

      <Box sx={styles.filter}>
        <Tooltip title="View" placement="top">
          <IconButton onClick={handleOpen}>
            <FilterListRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        {isLoading ? (
          <Skeleton height={20} width={80} variant="rounded" />
        ) : (
          <Typography variant="body2" aria-label="number of items">
            Items: {data?.count}
          </Typography>
        )}
      </Box>

      <Box sx={styles.tags}>
        <FilterResultsTag />
        <FilterTags />
      </Box>

      <Box sx={styles.input}>
        <TagSearch />
      </Box>
    </Box>
  )
}
