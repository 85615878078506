import { ActivityLayout } from '@components/activity/activity-layout'

import { Snapshot, UnitSnapshot } from '@components/activity/types'
import { Box, StyleProps, Typography } from '@mui/material'
import { WrapLink } from './shared-components'
import { Flag } from '@mui/icons-material'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    gap: 1.5,
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  flag: {
    color: 'R400',
  },
} satisfies StyleProps

type ActivityUnitFlaggedProps = {
  snapshot: Snapshot
}

export const ActivityUnitFlagged = ({ snapshot }: ActivityUnitFlaggedProps) => {
  const { to } = snapshot as UnitSnapshot

  return (
    <ActivityLayout.Root>
      <ActivityLayout.User by={snapshot.by} />

      <ActivityLayout.Content>
        <Box sx={styles.root}>
          <Typography
            variant="body1"
            noWrap
            sx={{
              maxWidth: {
                md: 520,
                lg: 660,
              },
            }}
          >
            Item flagged: <WrapLink to={`../review/detail/${to.id}`}>{to.content.stem}</WrapLink>
          </Typography>

          <Box sx={styles.description}>
            <Typography variant="subtitle2">Needs review</Typography>

            <Flag aria-label="flagged" sx={styles.flag} />
          </Box>
        </Box>
      </ActivityLayout.Content>

    </ActivityLayout.Root>
  )
}
