import { useState } from 'react'
import { Container, Grid, Box } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'
import { Avatar } from '@components/avatar'
import { AppBar } from '@components/appbar'
import { Menu, MenuItem, IconMenuButton } from '@components/menu'
import { Logo } from '@components/logo'
import { toast } from '@components/toaster'
import Tabs from './tab'
import { ResetModal } from './reset-modal'
import { useGetUser } from '@core/api/hooks'
import { useToggle } from '@core/hooks'
import { SwitchDomain } from './switch-domain'
import { useParams } from 'react-router-dom'

export const Header = () => {
  const { logout, user, getAccessTokenSilently } = useAuth0()
  const { customer = 'public' } = useParams()
  const { data } = useGetUser(customer)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isResetModalOpen, displayResetModal] = useToggle(false)
  const [isDisplaySwitchDomain, displaySwitchDomain] = useToggle(false)
  const open = Boolean(anchorEl)

  const handleDisplayResetModal = () => {
    displayResetModal()
    setAnchorEl(null)
  }

  const handleGetToken = async () => {
    const token = await getAccessTokenSilently()
    navigator.clipboard.writeText(token)
    setAnchorEl(null)
    toast.success({ showButton: false, heading: 'Success', message: 'Token copied on clipboard' })
  }

  const handleDisplaySwitchDomain = () => {
    displaySwitchDomain()
    setAnchorEl(null)
  }

  return (
    <AppBar>
      <Container sx={{ display: 'flex' }} maxWidth="xl">
        <Grid container>
          <Grid item md={2} display="flex" alignItems="center">
            <Logo width={130} height={32} alt="Catalog Logo" />
          </Grid>

          <Grid item md={9}>
            <Tabs />
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" gap={1} pr={2}>
          <Avatar
            size={32}
            bgcolor="R500"
            label={user?.name}
            src={user?.picture}
            alt={user?.name}
          />

          <IconMenuButton
            aria-label="profile-menu"
            aria-controls={open ? 'profile-menu' : undefined}
            aria-expanded={open}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <ArrowDropDown />
          </IconMenuButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={() => logout()} disableRipple>
              Logout
            </MenuItem>

            {data?.resetData && (
              <MenuItem onClick={displayResetModal} disableRipple>
                Reset data
              </MenuItem>
            )}

            {data?.copyToken && (
              <MenuItem onClick={handleGetToken} disableRipple>
                Token
              </MenuItem>
            )}
            {data?.tenants && data?.tenants.length > 1 && (
              <MenuItem onClick={handleDisplaySwitchDomain} disableRipple>
                Your Accounts
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Container>

      <ResetModal
        open={isResetModalOpen}
        onClose={handleDisplayResetModal}
        onCancel={handleDisplayResetModal}
      />

      {data && (
        <SwitchDomain
          openModal={isDisplaySwitchDomain}
          onClose={handleDisplaySwitchDomain}
          userProfile={data}
        />
      )}
    </AppBar>
  )
}
