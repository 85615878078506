import { ActivityLayout } from '@components/activity/activity-layout'

import { Snapshot, UnitSnapshot } from '@components/activity/types'
import { Box, StyleProps, Typography } from '@mui/material'
import { WrapLink } from './shared-components'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    gap: 1.5,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
} satisfies StyleProps

type ActivityUnitUnflaggedProps = {
  snapshot: Snapshot
}

export const ActivityUnitUnflagged = ({ snapshot }: ActivityUnitUnflaggedProps) => {
  const { to } = snapshot as UnitSnapshot

  return (
    <ActivityLayout.Root>
      <ActivityLayout.User by={snapshot.by} />

      <ActivityLayout.Content>
        <Box sx={styles.root}>
          <Typography
            variant="body1"
            noWrap
            sx={{
              maxWidth: {
                md: 520,
                lg: 660,
              },
            }}
          >
            Item unflagged: <WrapLink to={`../review/detail/${to.id}`}>{to.content.stem}</WrapLink>
          </Typography>

          <Box sx={styles.container}>
            <Typography variant="subtitle2">Review done</Typography>
          </Box>
        </Box>
      </ActivityLayout.Content>
    </ActivityLayout.Root>
  )
}
