import { Skeleton } from '@components/skeleton'
import { truncate } from '@core/formatters'
import { Box, StyleProps, Typography, useTheme, alpha } from '@mui/material'
import React, { SVGProps } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  LabelProps,
  Cell,
  ResponsiveContainer,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'

const styles: StyleProps = {
  root: {
    width: '100%',
    height: '100%',
    '& .custom-label': {
      backgroundColor: (theme) => theme.palette.N400,
      color: (theme) => theme.palette.N000,
      fontSize: 12,
      width: 52,
      height: 24,
      py: 0.5,
      px: 1,
      mr: 2,
      borderRadius: '3px',
    },
    '& .label-wrapper': {
      width: 100,
      height: 100,
      cursor: 'pointer',
    },
  },
  emptyContainer: {
    height: 120,
    bgcolor: 'N010',
    mt: 3,
    mr: 3,
    pt: 5.5,
    pr: 5.5,
    pb: 2,
    pl: 4,
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomLabel = (props: any) => {
  return (
    <foreignObject {...props} className="label-wrapper" x={props.x - 48} y={props.y - 12}>
      <div className="custom-label">{truncate(props.payload.value, 5)}</div>
    </foreignObject>
  )
}

const LabelEnd = (
  props: Omit<SVGProps<SVGTextElement>, 'viewBox'> &
    LabelProps & { commonNames: string[] | undefined }
) => {
  const { x, y, value, height, offset, width, name, commonNames } = props
  const hasLink = commonNames?.includes(name ?? '')
  const xText = (Number(x) ?? 0) + (Number(width) ?? 0) + Number(offset)
  const yText = Number(y) + Number(height) / 2

  return (
    <g>
      <text
        x={xText}
        y={yText}
        fontSize="16"
        fontFamily="sans-serif"
        textAnchor="start"
        dominantBaseline="middle"
      >
        {value}
      </text>
      {hasLink && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          x={xText + ((value as string)?.length ?? 1) * 10}
          y={yText - 10}
        >
          <g fill="none" fillRule="evenodd" transform="translate(-1138 -1528)">
            <rect width="1749" height="1718" />
            <path
              fill="#4A5568"
              // eslint-disable-next-line max-len
              d="M12.5858001,4.58579002 C13.3668001,3.80474002 14.6331001,3.80474002 15.4142001,4.58579002 C16.1952001,5.36683003 16.1952001,6.63316003 15.4142001,7.41421004 L12.4142001,10.4142001 C11.6331001,11.1953001 10.3668001,11.1953001 9.58577005,10.4142001 C9.19524005,10.0237001 8.56208004,10.0237001 8.17156004,10.4142001 C7.78103004,10.8047001 7.78103004,11.4379001 8.17156004,11.8284001 C9.73365005,13.3905001 12.2663001,13.3905001 13.8284001,11.8284001 L16.8284001,8.82843004 C18.3905001,7.26633004 18.3905001,4.73367002 16.8284001,3.17157002 C15.2663001,1.60948001 12.7337001,1.60948001 11.1716001,3.17157002 L9.67156005,4.67157002 C9.28103005,5.06210003 9.28103005,5.69526003 9.67156005,6.08579003 C10.0621001,6.47631003 10.6952001,6.47631003 11.0858001,6.08579003 L12.5858001,4.58579002 Z M7.58579004,9.58579005 C8.36683004,8.80474004 9.63316005,8.80474004 10.4142001,9.58579005 C10.8047001,9.97631005 11.4379001,9.97631005 11.8284001,9.58579005 C12.2190001,9.19526005 12.2190001,8.56210004 11.8284001,8.17157004 C10.2663001,6.60948003 7.73367004,6.60948003 6.17157003,8.17157004 L3.17157002,11.1716001 C1.60948001,12.7337001 1.60948001,15.2663001 3.17157002,16.8284001 C4.73367002,18.3905001 7.26633004,18.3905001 8.82843004,16.8284001 L10.3284001,15.3284001 C10.7190001,14.9379001 10.7190001,14.3047001 10.3284001,13.9142001 C9.93790005,13.5237001 9.30474005,13.5237001 8.91421004,13.9142001 L7.41421004,15.4142001 C6.63316003,16.1953001 5.36684003,16.1953001 4.58579002,15.4142001 C3.80474002,14.6332001 3.80474002,13.3668001 4.58579002,12.5858001 L7.58579004,9.58579005 Z"
              transform="translate(1138 1528)"
            />
          </g>
        </svg>
      )}
    </g>
  )
}

const SkeletonChart = () => {
  return (
    <Box mt={2} data-testid="loading">
      {[...Array(5)].map((key, index) => (
        <Box key={index} display="flex" mb={4}>
          <Box mr={2}>
            <Skeleton variant="rounded" width={44} height={24} />
          </Box>

          <Skeleton variant="rounded" width={420} height={25} />
        </Box>
      ))}
    </Box>
  )
}

const EmptyDataComponent = ({ text = 'No data found' }: { text?: string }) => {
  return (
    <Box justifyContent="center" alignItems="center" sx={styles.emptyContainer}>
      <Typography variant="body1" fontWeight={600} color="N500">
        {text}
      </Typography>
    </Box>
  )
}

export type ChartData = {
  [x: string]: string | boolean | number | undefined
}

type ChartProps = {
  data: ChartData[]
  chartSettings: {
    bar: { dataKey: string; color: string }[]
    legendName: string
    yName: string
  }
  commonNames?: string[]
  onClick?: (label: string | undefined) => void
  focusedLabel?: string
  onFocus?: (label: string | undefined) => void
  isLoading?: boolean
  noDataMessage?: string
}

const BAR_HEIGHT = 56
const LINK_ICON_WIDTH = 60
export const StackedBarChart = ({
  data,
  chartSettings,
  commonNames,
  onClick,
  focusedLabel,
  onFocus,
  isLoading,
  noDataMessage,
}: ChartProps) => {
  const theme = useTheme()
  const [barHovered, setBarHovered] = React.useState(false)
  if (isLoading) return <SkeletonChart />
  if (!data || !data.length) return <EmptyDataComponent text={noDataMessage} />
  const height = data?.length * BAR_HEIGHT

  const { bar: barSettings, legendName, yName } = chartSettings

  const largestLabel = data
    .filter((item) => commonNames?.includes(item?.name as string))
    .sort((a, b) => {
      return (b?.name as string).length - (a?.name as string).length
    })

  const xPadding = LINK_ICON_WIDTH + (largestLabel[0]?.name?.toString() || '').length * 16

  const handleClick = (nextState: CategoricalChartState) => {
    onClick?.(nextState?.activeLabel)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSyncMethod = (setHovered: (value: boolean) => void) => (_t: [], currentData: any) => {
    const same = _t.find(({ value }) => value === currentData.activeLabel)
    if (same) {
      setHovered(true)
      return (same as { value: string; index: number }).index
    }
    setHovered(false)
    return null
  }

  return (
    <Box sx={styles.root} data-testId="root-chart">
      <ResponsiveContainer width="99%" height={height}>
        <BarChart
          onClick={handleClick}
          onMouseEnter={() => setBarHovered(true)}
          onMouseLeave={() => {
            onFocus?.(undefined)
            setBarHovered(false)
          }}
          onMouseMove={(state) => onFocus?.(state.activeLabel)}
          data={data}
          syncId="anyId"
          layout="vertical"
          syncMethod={handleSyncMethod(setBarHovered)}
          margin={{
            top: 0,
            right: 50,
            left: 0,
            bottom: 0,
          }}
          barSize={25}
          barCategoryGap={0}
          barGap={0}
        >
          <XAxis type="number" hide padding={{ left: 8, right: xPadding }} cursor={'pointer'} />
          <YAxis
            type="category"
            axisLine={false}
            tickLine={false}
            dataKey={yName}
            tick={CustomLabel}
          />
          <Tooltip
            active={barHovered}
            cursor={
              !barHovered
                ? { fill: 'transparent' }
                : { fill: theme.palette.N010, cursor: 'pointer' }
            }
            content={() => <div></div>}
            animationEasing="ease-in-out"
          />
          {barSettings.map(({ dataKey, color }, index) => (
            <Bar
              key={index + dataKey}
              dataKey={dataKey}
              stackId="a"
              fill={color}
              cursor={'pointer'}
            >
              {data.map((entry, cellIndex) => (
                <Cell
                  key={cellIndex}
                  fill={!focusedLabel || focusedLabel === entry[yName] ? color : alpha(color, 0.5)}
                />
              ))}
              {barSettings.length === index + 1 && (
                <LabelList
                  dataKey={legendName}
                  position="right"
                  offset={20}
                  content={<LabelEnd commonNames={commonNames} />}
                />
              )}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
