import { Box, StyleProps } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Button, Drawer, Unit, Skeleton } from '@components'
import { useAppDispatch, useAppSelector } from '@core/store'

import { actions } from './review-slice'
import { useUnit } from '@core/api/hooks'

const styles: StyleProps = {
  reviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    px: 2.5,
    pt: 3,
    minWidth: 440,
    width: 440,
  },
}

export const ReviewDrawer = () => {
  const history = useNavigate()
  const dispatch = useAppDispatch()
  const selectedUnit = useAppSelector((state) => state.review.selectedUnit)
  const { data, isLoading } = useUnit(selectedUnit)

  const handleClose = () => {
    dispatch(actions.set({ selectedUnit: '' }))
  }

  return (
    <Drawer open={!!selectedUnit} onClose={handleClose} anchor="right">
      <Box sx={styles.reviewContainer}>
        <Unit
          material={data?.unit.project.material}
          content={data?.unit.content}
          labels={data?.unit.project.config?.labels}
        />

        {isLoading ? (
          <Box mt={2}>
            <Skeleton variant="rounded" width={140} height={35} />
          </Box>
        ) : (
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleClose()
                history({
                  pathname: `../review/detail/${data?.unit.id}`,
                  search: window.location.search,
                })
              }}
            >
              Review item tags
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}
