/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileOutput } from '../models/FileOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TsFilesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Create New File
   * @returns FileOutput Created
   * @throws ApiError
   */
  public createNewFile(): CancelablePromise<FileOutput> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/files',
    });
  }
  /**
   * List Files
   * @returns FileOutput OK
   * @throws ApiError
   */
  public listFiles(): CancelablePromise<Array<FileOutput>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/files',
    });
  }
  /**
   * Upload
   * @returns any Created
   * @throws ApiError
   */
  public upload({
    id,
    formData,
  }: {
    id: string,
    formData: {
      file: Blob;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/files/{id}/raw',
      path: {
        'id': id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Retrieve Raw File
   * @returns string OK
   * @throws ApiError
   */
  public retrieveRawFile({
    id,
  }: {
    id: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/files/{id}/raw',
      path: {
        'id': id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Retrieve File
   * @returns FileOutput OK
   * @throws ApiError
   */
  public retrieveFile({
    id,
  }: {
    id: string,
  }): CancelablePromise<FileOutput> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/files/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
