import { Menu, MenuButton, MenuItem, Typography, Modal } from '@components'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { Box, StyleProps } from '@mui/material'
import { generatePath, useNavigate } from 'react-router-dom'
import { PROJECTS_ROUTE } from '@core/constants/routes'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { UserSchema3 } from '@core/api/generated'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    px: 2,
  },
  list: {
    width: '100%',
    bgcolor: 'background.paper',
  },
  listItem: {
    p: 0,
    mt: 2,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
} satisfies StyleProps

export type SwitchDomainProps = {
  openModal: boolean
  onClose?: () => void
  userProfile: UserSchema3
}

export const SwitchDomain = ({ openModal, onClose, userProfile }: SwitchDomainProps) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const client = useQueryClient()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSwitchDomain = (tenant: string) => {
    navigate(`/${tenant}/` + generatePath(PROJECTS_ROUTE))
    setAnchorEl(null)
    onClose?.()
    client.removeQueries()
  }

  return (
    <Modal open={openModal} onClose={onClose}>
      <Box sx={styles.root}>
        <Typography variant="h4">Your Accounts</Typography>

        <Box mt={2}>
          <Typography variant="body1" fontWeight={600}>
            Account
          </Typography>
        </Box>

        <Box>
          <MenuButton
            aria-expanded={open}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Choose one account to activate
          </MenuButton>
        </Box>
        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          {userProfile?.tenants.map((tenant) => (
            <MenuItem onClick={() => handleSwitchDomain(tenant)} key={tenant}>
              {tenant}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Modal>
  )
}
