import { Header } from '@containers/header'
import { useGetUser } from '@core/api/hooks'
import { Box, Container, StyleProps } from '@mui/material'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

const styles: StyleProps = {
  root: {
    display: 'grid',
    gridTemplate: `
      "header" 48px
      "main" 1fr
      / auto
    `,
    minHeight: '100vh',
  },
  header: {
    gridArea: 'header',
  },
  main: {
    gridArea: 'main',
    pt: 5,
    overflow: 'auto',
  },
}

export default function Layout() {
  const { data } = useGetUser()

  const { customer } = useParams()
  useEffect(() => {
    const title = 'Finetune Catalog'
    document.title = customer && data?.tenants.includes(customer) ? `${title} - ${customer}` : title
  }, [data])

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Header />
      </Box>

      <Container sx={styles.main} maxWidth="xl">
        <Outlet />
      </Container>
    </Box>
  )
}
