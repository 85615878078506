import { ActivityLayout } from '@components/activity/activity-layout'

import { Snapshot, UnitSnapshot } from '@components/activity/types'
import { Box, StyleProps, Typography } from '@mui/material'
import { TagDisplay, WrapLink } from './shared-components'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    gap: 1.5,
  },
  tagsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
} satisfies StyleProps

type ActivityUnitFinalizedProps = {
  snapshot: Snapshot
}

export const ActivityUnitFinalized = ({ snapshot }: ActivityUnitFinalizedProps) => {
  const { to } = snapshot as UnitSnapshot

  return (
    <ActivityLayout.Root>
      <ActivityLayout.User by={snapshot.by} />

      <ActivityLayout.Content>
        <Box sx={styles.root}>
          <Typography
            variant="body1"
            noWrap
            sx={{
              maxWidth: {
                md: 520,
                lg: 660,
              },
            }}
          >
            Tag finalized: <WrapLink to={`../review/detail/${to.id}`}>{to.content.stem}</WrapLink>
          </Typography>

          <Box sx={styles.tagsContainer}>
            <Typography variant="subtitle2">Closed</Typography>

            <Box sx={styles.tags}>
              {to.tags.map((tag, index) => (
                <TagDisplay index={index} tag={tag} key={index} />
              ))}
            </Box>
          </Box>
        </Box>
      </ActivityLayout.Content>
    </ActivityLayout.Root>
  )
}
