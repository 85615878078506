import { useQuery } from '@tanstack/react-query'
import { ApiError, UserSchema3 } from '../generated'
import { useApi } from './api'

export const useGetUser = (customer: string | null = null) => {
  const api = useApi(customer)
  const baseUrl = api.httpRequest.config.BASE
  const tenant = customer || baseUrl.split('/').pop()

  return useQuery<UserSchema3, ApiError>({
    queryKey: ['getUser', tenant],
    queryFn: () => api.getUser(),
  })
}

export const useGetCustomer = (customer: string | null = null) => {
  const api = useApi(customer)
  const baseUrl = api.httpRequest.config.BASE
  const tenant = customer || baseUrl.split('/').pop()

  return useQuery<UserSchema3, ApiError>({
    queryKey: ['getUser', tenant],
    queryFn: () => api.getUser(),
    throwOnError: false,
  })
}
