import { useAuth0 } from '@auth0/auth0-react'
import { ApiClient } from '../generated'
import { ApiRequestOptions } from '../generated/core/ApiRequestOptions'
import { FTC_API, IS_STORYBOOK } from '@core/config'
import { useParams } from 'react-router-dom'

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

export const useApi = (newCustomer: string | null = null) => {
  const { getAccessTokenSilently } = useAuth0()
  const { customer = newCustomer } = useParams()

  if (IS_STORYBOOK) return new ApiClient({ BASE: FTC_API }).default

  const token = getAccessTokenSilently()
  return new ApiClient({
    BASE: FTC_API + '/tenants/' + customer,
    TOKEN: token as unknown as Resolver<string>,
  }).default
}
