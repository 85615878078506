import { Box, Grid, Stack, styled, StyleProps } from '@mui/material'
import { useParams } from 'react-router-dom'

import { Chip, Skeleton, TextField } from '@components'

import { Typography } from '@components/typography'
import ReviewMenu from './review-menu'
import ReviewTable from './review-table'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback } from 'react'
import { useProject, useUpdateTitle } from '@core/api/hooks'
import { ReviewTagDistribution } from './review-tag-distribution'

const styles: StyleProps = {
  headerLabels: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 4,
  },
  histogramContainer: {
    mt: 5,
    height: 130,
  },
  resultsContainer: {
    mb: 1,
    mt: 4,
  },
  reviewTableContainer: {
    pt: 3,
    pb: 4,
  },
}

const ReviewChip = styled(Chip)`
  margin-right: 8px;
`

const ProjectTitle = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontWeight: 500,
    fontSize: 29,
    border: '2px solid transparent',
    '&:hover': {
      background: 'transparent',
      border: `2px solid ${theme.palette.B100}`,
    },
  },
  '& .MuiInputBase-input': {
    color: theme.palette.N800,
  },
}))

export default function Review() {
  const { projectId } = useParams() as { projectId: string }
  const { data, isLoading } = useProject(projectId)

  const title = useUpdateTitle({ id: projectId })

  const update = (e: ChangeEvent<HTMLInputElement>) => {
    title.mutate({
      title: e.target.value,
    })
  }

  const updateTitle = useCallback(debounce(update, 200), [])
  const errorMessage = title.error?.body?.detail?.[0]?.msg

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        {isLoading ? (
          <Skeleton width={200} height={38} variant="rounded" />
        ) : (
          <ProjectTitle
            defaultValue={data?.title || ''}
            fullWidth
            onChange={updateTitle}
            error={!!errorMessage}
            helperText={errorMessage}
            inputProps={{
              'aria-label': 'project title'
            }}
          />
        )}

        <Box sx={styles.headerLabels}>
          {isLoading ? (
            <Stack direction="row" spacing={2}>
              <Skeleton width={48} height={28} variant="rounded" />
              <Skeleton width={48} height={28} variant="rounded" />
            </Stack>
          ) : (
            <Box>
              {data?.material && (
                <ReviewChip
                  bgcolor="P075"
                  textColor="N600"
                  label={data?.material.replace('_', ' ')}
                />
              )}
              {data?.framework.shortName && (
                <ReviewChip color="primary" label={data?.framework.shortName} />
              )}
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item md={12}>
        <Box sx={styles.histogramContainer}>
          <ReviewTagDistribution />
        </Box>

        <Box sx={styles.resultsContainer}>
          <Typography variant="h4">Results</Typography>
        </Box>
      </Grid>

      <ReviewMenu />

      <Grid item md={12}>
        <Box sx={styles.reviewTableContainer}>
          <ReviewTable />
        </Box>
      </Grid>
    </Grid>
  )
}
