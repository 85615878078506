import { ActivityLayout } from '@components/activity/activity-layout'

import { CommentSnapshot, Snapshot } from '@components/activity/types'
import { Box, StyleProps, Typography } from '@mui/material'
import { WrapLink } from './shared-components'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    gap: 1.5,
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
} satisfies StyleProps

type ActivityEvidenceAddedProps = {
  snapshot: Snapshot
}

export const ActivityEvidenceAdded = ({ snapshot }: ActivityEvidenceAddedProps) => {
  const { by, to } = snapshot as CommentSnapshot
  const userName = `${by.firstName} ${by.lastName ?? ''}`

  return (
    <ActivityLayout.Root>
      <ActivityLayout.User by={snapshot.by} />

      <ActivityLayout.Content>
        <Box sx={styles.root}>
          <Typography
            variant="body1"
            noWrap
            sx={{
              maxWidth: {
                md: 520,
                lg: 660,
              },
            }}
          >
            Evidence left by {userName} on:{' '}
            <WrapLink to={`../review/detail/${to.objectId}`}>{to.object.content.stem}</WrapLink>
          </Typography>

          <Box sx={styles.description}>
            <Typography variant="subtitle2">New evidence</Typography>
          </Box>
        </Box>
      </ActivityLayout.Content>
    </ActivityLayout.Root>
  )
}
