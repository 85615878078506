import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material'

type TypographyProps = Pick<
  MuiTypographyProps,
  'variant' | 'children' | 'color' | 'paragraph' | 'fontWeight' | 'noWrap' | 'textAlign'
>

export const Typography: React.FC<TypographyProps> = (props: TypographyProps) => {
  return <MuiTypography {...props} />
}
