import { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  StyleProps,
} from '@mui/material'

import CheckIcon from '@assets/images/icon-circle-check-24.svg?react'
import { Button, Tooltip } from '@components'

const styles: StyleProps = {
  menu: {
    display: 'flex',
    '& :hover': {
      textDecoration: 'underline',
    },
    '& .Mui-disabled': {
      opacity: 1,
    },
    py: 1.5,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  title: {
    flex: 1,
    cursor: 'grabbing',
    fontSize: 16,
    fontWeight: 500,
  },
  paper: {
    width: 350,
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: (theme) => theme.zIndex.modal - 1,
  },
  menuList: {
    p: 0,
  },
  listItem: {
    py: 0.25,
    pb: 0.25,
  },
  cancelContainer: {
    bgcolor: 'N010',
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    '& .Mui-disabled': {
      opacity: 1,
    },
  },
  downloadStatus: {
    opacity: 0.38,
  },
  itemsContainer: {
    maxHeight: 200,
    overflowY: 'auto',
  },
}

export type File = {
  filename: string
  blob: string | null
  contentType: string
}

export type DownloadItemProps = {
  index: number
  file: File
  onDownload: (file: HTMLAnchorElement) => void
}

export type DownloadDialogProps = {
  loading: boolean
  files: File[]
  onClear: () => void
  onDownload: (file: HTMLAnchorElement) => void
}

function DownloadItem({ index, file, onDownload }: DownloadItemProps) {
  const loading = !file.blob

  const onClick = () => {
    if (!file.blob) return

    const link = document.createElement('a')
    link.href = file.blob
    link.download = file.filename

    onDownload(link)
  }

  return (
    <Tooltip title={file.filename} placement="left">
      <MenuItem
        aria-label="download item"
        disabled={loading}
        onClick={onClick}
        sx={styles.menu}
        autoFocus
        divider
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary={`${index} . ${file.filename}`}
        />

        <ListItemIcon>
          {loading ? (
            <CircularProgress sx={{ color: 'B200' }} size={20} aria-label="loading download" />
          ) : (
            <CheckIcon aria-label="check icon" />
          )}
        </ListItemIcon>
      </MenuItem>
    </Tooltip>
  )
}

export const DownloadDialog = ({ loading, files, onClear, onDownload }: DownloadDialogProps) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  if (files?.length === 0) return null

  return (
    <Paper sx={styles.paper} elevation={3}>
      <MenuList autoFocus={false} sx={styles.menuList}>
        <ListItem divider sx={styles.listItem}>
          <Typography sx={styles.title} variant="h6" aria-label="downloads number">
            Downloads {files?.length}
            {loading ? '...' : ''}
          </Typography>

          <IconButton onClick={handleClick} aria-label={open ? 'expand less' : 'expand more'}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItem>

        <Collapse in={open} timeout="auto" aria-expanded={open} data-toggle="collapse">
          <ListItem dense sx={styles.cancelContainer}>
            <Typography variant="body2" sx={styles.downloadStatus} aria-label="download status">
              {loading ? 'Processing...' : 'Done'}
            </Typography>

            <Button size="small" variant="text" color="info" onClick={onClear}>
              Clear
            </Button>
          </ListItem>

          <Box sx={styles.itemsContainer}>
            {files.map((file, index) => {
              return (
                <DownloadItem file={file} index={index + 1} key={index} onDownload={onDownload} />
              )
            })}
          </Box>
        </Collapse>
      </MenuList>
    </Paper>
  )
}
