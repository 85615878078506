import { Link } from '@mui/material'
import { ErrorPage } from './base'
import { useGetUser } from '@core/api/hooks'

export const NotFoundErrorPage = () => {
  const { data } = useGetUser('public')
  const customer = window.location.pathname.split('/')[1]

  return (
    <ErrorPage
      href={data?.tenants.includes(customer) ? `/${customer}/projects` : '/'}
      code={404}
      message="The page you are looking for might have been removed or is temporarily unavailable."
    />
  )
}

export const ForbiddenErrorPage = () => (
  <ErrorPage code={403} message="You don’t have permission to access this resource." />
)

export const ServerErrorPage = () => (
  <ErrorPage
    code={500}
    message={
      <>
        Try to refresh this page or feel free to{' '}
        <Link href="mailto:ft-support@academicmerit.com" underline="none" color="B200">
          contact us
        </Link>{' '}
        if the problem persists.
      </>
    }
  />
)
