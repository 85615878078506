export const toOptionCode = (index: number): string => {
  const charCode = index + 97;

  if (charCode < 0 || charCode > 122) return '';

  return String.fromCharCode(charCode);
};

export const formatTextURL = (text: string) => {
  const regex = / /g
  const trimText = text.trim()
  return trimText.replace(regex, '+')
}
